.form {
  margin-top: 20px;
}
.inputs_wrapper {
  margin-bottom: 28px;
}
.input_item {
  width: 100%;
  margin-bottom: 24px;
}
.button_wrapper {
  text-align: right;
}
.submit_btn {
  padding: 12px;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;

  background: #4caf50 !important;
  box-shadow: 0px 1px 1px rgba(7, 73, 1, 0.14), 0px 2px 1px rgba(7, 73, 1, 0.12),
    0px 1px 3px rgba(7, 73, 1, 0.2);
  border-radius: 5px;

  &:disabled {
    background: rgba(0, 0, 0, 0.12) !important;
  }
}
.cancel_btn {
  margin-right: 24px !important;

  background-color: #fff !important;
}
