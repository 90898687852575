.table_wrapper {
  max-height: 614px;
  overflow: scroll;
}
.table {
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
  thead {
    position: sticky;
    top: 0px;
    background-color: #fff;
    z-index: 10;
  }
  th {
    padding: 10px;

    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.44px;

    color: #9ea3a6;

    &:first-child {
      padding: 10px 10px 10px 50px;
    }
    &:last-child {
      padding: 10px 50px 10px 10px;
    }
  }
}
.block_info {
  margin: 16px 0 25px;
}
.button_wrapper {
  display: flex;
  justify-content: center;
  gap: 0 24px;
}
.cancel_btn {
  background-color: #fff;
}
.submit_btn {
  background: #4caf50 !important;
  box-shadow: 0px 1px 1px rgba(7, 73, 1, 0.14), 0px 2px 1px rgba(7, 73, 1, 0.12),
    0px 1px 3px rgba(7, 73, 1, 0.2);
  border-radius: 5px;
}
.clipboardPopup {
  position: absolute;
  bottom: 20px;
  right: 30px;
  z-index: 200;
}
