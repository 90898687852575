@import "./vars.scss";

* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}
aside,
nav,
footer,
header,
section {
  display: block;
}
html,
body {
  height: 100%;
}
body {
  font-family: $mainFont;
  color: #212121;
  font-size: 18px;
  line-height: 25px;
  overflow-x: hidden;
}
input,
button,
textarea {
  font-family: $mainFont;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
}
h1 {
  font-size: 35px;
  line-height: 41px;
}
h2 {
  font-size: 30px;
  line-height: 36px;
}
h3 {
  font-size: 24px;
  line-height: 29px;
}
