.block_info {
  margin: 16px 0 25px;
}
.input_item {
  margin-bottom: 28px;
}
.button_wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 0 24px;
}
.cancel_btn {
  background-color: #fff;
}
.submit_btn {
  background: #4caf50 !important;
  box-shadow: 0px 1px 1px rgba(7, 73, 1, 0.14), 0px 2px 1px rgba(7, 73, 1, 0.12),
    0px 1px 3px rgba(7, 73, 1, 0.2);
  border-radius: 5px;
}
