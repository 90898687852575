@import "../../../vars.scss";

.userRow td {
  // height: 40px;
  padding: 0 10px !important;

  vertical-align: middle;

  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.44px;
  color: #212121;

  &:first-child {
    padding: 0px 10px 0px 50px !important;
  }
  &:last-child {
    padding: 0px 50px 0px 10px;
  }
}
.gray {
  color: #78909c !important;
}
.userRow .link {
  position: relative;

  color: #3979f7;
  cursor: pointer;

  a,
  a:visited {
    color: #3979f7;
  }

  .linkInner {
  }

  .linkIcon {
    margin-right: 5px;
    &:hover {
      background-color: transparent !important;

      svg {
        path {
          fill: #3979f7 !important;
        }
      }
    }
  }
}
.resendBtn {
  width: 84px;
  height: 20px;

  font-size: 10px !important;
  line-height: 20px !important;
  color: #fffcfc !important;

  background: $accent-color !important;
  border-radius: 4px;
}
