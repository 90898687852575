@import "../../vars.scss";

.LoginPage {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.title,
.subtitle {
  max-width: 533px;
  color: #ffffff;
}

.title {
  // margin-bottom: 19px;
  margin-bottom: -30px;
}

.info_section,
.form_section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.info_section {
  max-width: 50%;
  background-color: $accent-color;
}

.form {
  width: 362px;
  padding: 24px 40px 34px;
}

.form_header {
  margin-bottom: 58px;
  position: relative;
}

.form_error {
  position: absolute;
  top: calc(100% + 8px);
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ed3636;
}

.input_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  margin-bottom: 20px;
}

.submit_btn {
  background-color: $accent-color !important;
  color: #ffffff !important;
  height: 52px !important;
}
