.table_wrapper {
  max-height: 614px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.table {
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
  thead {
    position: sticky;
    top: 0px;
    background-color: #fff;
    z-index: 10;
  }
  th {
    padding: 10px;

    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.44px;

    color: #78909c;

    &:first-child {
      padding: 10px 10px 10px 50px;
    }
  }
}
@media (max-width: 500px) {
  .table_wrapper {
    overflow-x: auto;
  }
}
