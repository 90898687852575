@import "../../../vars.scss";

.tasksRow {
  box-sizing: content-box;
  position: relative;
  display: grid;
  grid-template-columns: 275px 210px 150px 140px 110px 100px;
  justify-content: space-between;
  align-items: center;
  gap: 0 10px;

  padding: 8px 0px 8px 0px;

  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.44px;
  color: #212121;
  border-top: 1px solid #e3e5e5;
}
.tasksRow.active {
  background-color: #dcf7e3;
}
.actions {
  display: flex;
}
.expand {
  position: sticky;
  left: 0;
  .name {
    display: inline-block;
    padding-left: 5px;

    font-weight: 500;
  }
}
.rowSelect {
  background-color: #dcf7e3;
}
.expandIcon {
  min-width: 24px;
  height: 21px;

  border-radius: 4px !important;
}
.expandInner {
  display: flex;
  align-items: flex-start;
  word-break: break-all;
}
.gray {
  color: #78909c !important;
  word-break: break-all;
}
.btn {
  min-width: 20px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  padding: 4px 6px !important;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: transparent !important;
  }
}
.hoveredGreen {
  path {
    fill: #4caf50;
  }
}
.hoveredRed {
  path {
    fill: #e00000;
  }
}
.innerTask,
.taskSelect,
.currentTask {
  display: grid;
  gap: 0 10px;
  // width: 100%;

  // height: 42px;

  color: #5e6368;

  border-top: 1px solid #e3e5e5;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.44px;
  color: #78909c;
}
.currentTask {
  padding: 10px 0px 10px 0px;
  grid-template-columns: 275px 210px 150px 140px 110px 100px;
  justify-content: space-between;

  letter-spacing: 0.44px;

  & .add {
    font-weight: 500;
  }
}

.innerTask,
.taskSelect {
  // width: 100%;
  position: relative;
  padding: 10px 0px 10px 0px;

  grid-template-columns: 205px 210px 150px 140px 110px 95px;
  justify-content: space-between;
}
.innerTask {
  .name {
    display: flex;

    button {
      height: 21px;
      margin-right: 15px;
      background-color: transparent;
    }
  }
  .employee {
    display: inline-block;
    position: relative;
    height: 21px;

    .popup {
      position: absolute;
      display: inline-block;
      left: 0%;
      top: 0;

      transform: translate(calc(-50% + 12px), calc(-100% - 10px));
    }
  }
}

.currentTask .add {
  display: flex;

  .buttonWrapper {
    height: 23px;
    width: 30px;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;

    button {
      position: relative;
      width: 14px;
      padding: 3px 4px;

      background-color: transparent;

      & .icon {
        position: relative;
      }
    }
  }
}
.buttonWrapper {
  .addPrevBtn {
    &.hoveredGreen {
      & .icon {
        top: -1px;
      }
    }
  }
  .addNextBtn {
    &.hoveredGreen {
      & .icon {
        top: 2px;
        right: 2px;
      }
    }
  }
}

.prevTasks,
.nextTasks {
  .gray {
    color: #c6cacc !important;
  }
  .taskSelect {
    color: #c6cacc;
    height: 77px;
  }
}

.delete,
.edit,
.expandIcon,
.addNextBtn,
.addPrevBtn,
.unlinkBtn {
  position: relative;

  .popup {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    z-index: 103;
  }
}
.delete {
  .popup {
    width: 142px;

    transform: translate(calc(-50% + 17px), -40px);

    // @media (min-width: 970px) and (max-width: 1140px) {
    //   transform: translate(
    //     calc(0% + 90 * ((100vw - 970px) / (1140 - 970))),
    //     calc(130% - 10 * ((100vw - 970px) / (1140 - 970)))
    //   );
    // }

    // @media (max-width: 970px) {
    //   transform: translate(0, 120%);
    // }
  }
}
.edit {
  .popup {
    width: 192px;

    transform: translate(calc(-50% + 20px), -40px);
  }
}
.expandIcon {
  .popup {
    width: 150px;
    transform: translate(calc(-50% + 14px), -45px);
  }
}

.addPrevBtn {
  .popup {
    width: 263px;
    transform: translate(calc(-50% + 6px), -43px);
  }
}
.addNextBtn {
  .popup {
    width: 263px;
    transform: translate(calc(-50% + 6px), -43px);
  }
}
.unlinkBtn {
  .popup {
    width: 130px;
    transform: translate(calc(-50% + 10px), -45px);
  }
}
.more {
  display: none;
  position: absolute;
  z-index: 120;
  top: 50%;
  right: 20px;

  width: 24px;
  height: 24px;

  transform: translateY(-50%);
  cursor: pointer;

  &:hover {
    top: 8.5px;
    right: 19px;

    width: 180px;
    height: 114px;
    padding-left: 155px;
    padding-bottom: 90px;

    transform: translateY(0%);

    .popupMenu {
      display: block;
    }
  }
}
.innerTask:hover {
  .more {
    display: block;
  }
}
.popupMenu {
  display: none;
  position: absolute;
  z-index: 211;
  right: 24px;
  top: 30px;

  // transform: translateX(-100%);
}
.menuWrapper {
  margin: -10px;
}
.menuItem {
  width: 151px;
  padding: 4px 12px;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: rgba(0, 0, 0, 0.6);

  background-color: #fff;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  &:first-child {
    padding-top: 8px;
  }

  &:last-child {
    padding-bottom: 8px;
  }
}
