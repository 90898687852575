.dialog {
  min-width: 602px !important;
  padding: 25px;
}
.dialog_paper {
  max-width: 100% !important;
}
.title {
  padding: 0 !important;
  font-family: "Quicksand", sans-serif !important;
  color: #343a40 !important;
}
.content {
  padding: 0 !important;
}
