@import "../../../vars.scss";

.projectsRow {
  border: 1px solid transparent;
  transition: all 0.5s;

  &:hover {
    background: #dcf7e3;
    border-color: #e3e5e5;
  }
}
.projectsRow td {
  // height: 40px;
  padding: 0px 10px !important;

  vertical-align: middle;

  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.44px;
  color: #212121;

  &:first-child {
    padding: 0px 10px 0px 50px !important;
  }
}
.nameInner {
  display: flex;
  align-items: flex-start;
  padding: 9px 0;
}
.gray {
  color: #78909c !important;
}
.btn {
  min-width: 40px !important;
  color: #5e6368 !important;

  margin: 0 5px !important;
}
.import_btn {
  transform: scale(-1, 1);
}
