.popup {
  position: relative;
  z-index: 110;
  padding: 8px 10px;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: rgba(0, 0, 0, 0.6);

  background: #fffcfc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  &.not_arrow {
    overflow: hidden;
  }
}
.triangle {
  position: absolute;
  bottom: 1px;
  left: 50%;

  transform: translate(-50%, 100%);

  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 8.5px 0 8.5px;
  border-color: #fffcfc transparent transparent transparent;
  line-height: 0;
}
