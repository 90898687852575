.table_wrapper {
  max-height: 614px;
  overflow-y: visible;
}
.table {
  // background-color: #ffffff;
  border-radius: 5px;
  position: relative;
  // overflow-x: scroll;

  .tableHeader {
    height: 40px;
    // background-color: #fff;
    border-top: 1px solid #e3e5e5;

    display: grid;
    grid-template-columns: 275px 210px 150px 140px 110px 100px;
    justify-content: space-between;
    gap: 0 10px;
    align-items: center;
  }

  .headerItem {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 4px;

    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.44px;
    color: #78909c;
    text-transform: uppercase;

    & .lowercase {
      text-transform: lowercase;
    }
  }
}
.block_info {
  margin: 16px 0 25px;
}
.button_wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 0 24px;
}
.cancel_btn {
  background-color: #fff;
}
.submit_btn {
  background: #4caf50 !important;
  box-shadow: 0px 1px 1px rgba(7, 73, 1, 0.14), 0px 2px 1px rgba(7, 73, 1, 0.12),
    0px 1px 3px rgba(7, 73, 1, 0.2);
  border-radius: 5px;
}
