.MainContainer {
  position: relative;
  min-height: 100vh;
  padding-top: 120px;

  background: #eff2f5;
}

.header {
  position: fixed;
  top: 0;
  z-index: 150;

  width: 100%;
  padding: 0px 16px;

  background: #ffffff;
  border-bottom: 1px dashed #e3e5e5;
}

.appbar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 64px;
}
.subheader {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  height: 56px;
}

.loader {
  position: fixed;
  z-index: 100;

  width: 100%;
}

.logo {
  position: relative;
  width: 200px;
  height: 40px;

  text-align: center;
}
.nav {
  margin: 0 auto;
  width: 1330px;
  height: 100%;
}
.navList {
  display: flex;
  align-items: baseline;

  height: 100%;
}

.link,
.logout {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  cursor: pointer;

  &:hover {
    color: #006fc0;
  }
}
.navItem {
  height: 100%;
  transform: translateY(1px);
}
.link {
  display: inline-block;
  height: 100%;

  border: 1px solid transparent;
  border-radius: 4px 4px 0px 0px;
  button {
    height: 100%;
    padding: 2px 16px !important;

    span {
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      text-transform: initial;
      letter-spacing: 0.44px;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  &.active {
    border-color: #c6cacc;
    border-bottom-color: #fff;

    button {
      span {
        color: #5e6366;
      }
    }
  }
}

.logout {
  padding: 8px 9px !important;

  border-radius: 10px !important;
  transition: all 0.3s !important;

  &:hover {
    background-color: #fff !important;

    .btn_text {
      color: #f36e21;
    }
  }

  .btn_text {
    width: 78px;

    font-family: "Quicksand", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    letter-spacing: 0.15px;
    text-transform: uppercase;
    transition: all 0.3s !important;
  }
}

.container {
  margin: 0 auto;
  padding: 0 15px 85px;
  max-width: 1330px;
}
