.groupsRow {
  height: 40px;
  padding: 0 60px 0 25px;
  display: flex;
  align-items: center;

  vertical-align: middle;

  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.44px;
  color: #212121;

  border-top: 1px solid #e3e5e5;
  cursor: pointer;

  &:hover {
    background-color: #e3e5e5;

    .actions {
      display: flex;
    }
  }
}
.active {
  background-color: #f5f5f5;
}
.tasks {
  // padding-left: 50px;
}
.actions {
  display: none;
}
.actionInner {
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 3px;

  background-color: #fff;
  border-radius: 10px;
}
.btn {
  min-width: 18px !important;
  color: #5e6368 !important;
  padding: 4px 6px !important;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: transparent !important;
  }

  &.addTask {
    &:hover {
      svg path {
        fill: #4caf50;
      }
    }
  }

  &.addGroup {
    &:hover {
      svg path {
        fill: #4caf50;
      }
    }
  }

  &.edit {
    &:hover {
      svg path {
        fill: #4caf50;
      }
    }
  }

  &.delete {
    &:hover {
      svg path {
        fill: #e00000;
      }
    }
  }
}
.folder {
  flex: 0 1 1030px;
  .name {
    padding-left: 14px;

    font-weight: 500;
  }
}
.folderInner {
  padding: 9px 0;
}
.folderIcon {
  width: 27px;
  height: 21px;

  &:hover {
    background-color: transparent !important;

    svg {
      path {
        fill: #4caf50 !important;
      }
    }

    &.closedFolder {
      &::before {
        background-color: #4caf50;
      }
    }
  }

  &.active {
    svg {
      path {
        fill: #4caf50 !important;
      }
    }

    &.closedFolder {
      &::before {
        background-color: #4caf50;
      }
    }
  }

  &.closedFolder {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 5px;
      height: 5px;

      background-color: #5e6368;
      border-radius: 50%;
    }
  }
}
