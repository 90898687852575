.users {
  margin-top: 32px;
}
.add_wrapper {
  position: relative;
  z-index: 11;
  margin-bottom: 24px;

  text-align: end;

  button {
    padding: 10px 16px 10px 56px !important;

    &::before {
      content: "";
      position: absolute;
      top: 13px;
      left: 16px;

      width: 32px;
      height: 32px;

      background-image: url(../../../public/assets/plus-icon.svg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
.add_btn {
  font-family: "Quicksand", sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;

  line-height: 28px;
  letter-spacing: 0.15px !important;

  color: #f36e21 !important;
  text-transform: initial !important;

  background-color: #fff !important;
  box-shadow: 0px 4px 8px rgba(243, 110, 33, 0.16),
    0px 2px 4px rgba(243, 110, 33, 0.24) !important;
  border-radius: 10px !important;

  &:hover {
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(243, 110, 33, 0.3),
      0px 2px 4px rgba(243, 110, 33, 0.5);
  }
}
.tableWrapper {
  padding: 14px 0 0;

  background: #ffffff;
  border-radius: 4px;
}
.title {
  position: relative;
  margin-left: 15px;
  margin-bottom: 24px;
  padding-left: 35px;

  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;

  color: #5e6368;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 28px;
    height: 28px;

    background-image: url(../../../public/assets/users-icon.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.no_rows {
  z-index: 10;
  position: absolute;
  width: 100%;
  height: calc(100% - 121px);
  top: 121px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text {
    max-width: 860px;
    font-family: "Quicksand", sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 56px;
    text-align: center;
    color: #212121;
  }
}
